import React, { useState, useEffect } from "react";

import OrderProgressBar from "./order-progressbar";
import DataTable from "react-data-table-component";
import { customStylesTable } from "../../../services/modal";

const OrderInformation = ({ dataOrder, defaultDataFedex, page }) => {
  const [uppage, setUpPage] = useState(0);
  useEffect(() => {
    if (uppage === 0) window.scrollTo(0, 0);
  });

  const [isub_menu, setISubMenu] = useState(false);
  const handleClickItem = (e) => {
    if (isub_menu === false) {
      e.target.closest("div").classList.add("item_selected");
      setISubMenu(true);
      setUpPage(1);
    } else {
      e.target.closest("div").classList.remove("item_selected");
      setISubMenu(false);
      setUpPage(1);
    }
  };

  const columns = [
    {
      name: "Item",
      center: true,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://drive.google.com/thumbnail?authuser=0&sz=w120&id=${
              row.image.match(/[-\w]{25,}/)
                ? row.image.match(/[-\w]{25,}/)[0]
                : ""
            }`}
            alt="product"
            className="table_image"
          />
          <span
            className="product_down"
            onClick={handleClickItem}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleClickItem();
              }
            }}
          >
            &nbsp;&nbsp;▼
          </span>
          <div className="product_info">
            <p className="product_info_number">{row.articleNumber}</p>
            <p className="product_info_color">Color {row.colorNumber}</p>
            <p className="product_info_quantity">Quantity {row.quantity}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Weight",
      center: true,
      selector: (row) => "Where to get it?",
    },
    {
      name: "Size",
      center: true,
      selector: (row) => "Where to get it?",
    },
    {
      name: "Total Price",
      center: true,
      selector: (row) => "$ " + row.totalPrice,
    },
    {
      name: "Shipment Order",
      center: true,
      selector: (row) => "Where to get it?",
    },
  ];

  return (
    <>
      <OrderProgressBar page={page}></OrderProgressBar>
      <form>
        <h2 className="order-subtitle">Order Information Product</h2>
        {dataOrder.detailProducts.length > 0 ? (
          <div className="table_style_product">
            <DataTable
              columns={columns}
              data={dataOrder.detailProducts}
              customStyles={customStylesTable}
            />
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
        <h2 className="order-subtitle">Order Information Swatch</h2>
        {dataOrder.detailSwatch.length > 0 ? (
          <div className="table_style_product">
            <DataTable
              columns={columns}
              data={dataOrder.detailSwatch}
              customStyles={customStylesTable}
            />
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
        <h2 className="order-subtitle">Customs Clearance Detail</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="payment">Payment Type</label>
            <input value={defaultDataFedex.paymentType} name="payment"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="commodities">Commodities Description</label>
            <input value={defaultDataFedex.commoditiesDescription} name="commodities"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="countryManufacture">Country of Manufacture</label>
            <input value={defaultDataFedex.countryOfManufacture} name="countryManufacture"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="quantity">Quantity</label>
            <input value={defaultDataFedex.quantity} name="quantity"></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="quantityUnits">Quantity Units</label>
            <input value={defaultDataFedex.quantityUnits} name="quantityUnits"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="amount">Amount</label>
            <input value={defaultDataFedex.amount} name="amount"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="currency">Currency</label>
            <input value={defaultDataFedex.currency} name="currency"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="customValueAmount">Customs Value Amount</label>
            <input value={defaultDataFedex.customsValueAmount} name="customValueAmount"></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="customValueCurrency">Customs Value Currency</label>
            <input value={defaultDataFedex.customsValueCurrency} name="customValueCurrency"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="unitWeight">Unit Weight</label>
            <input value={defaultDataFedex.commoditiesUnitWeight} name="unitWeight"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="unitValue">Unit Value</label>
            <input value={defaultDataFedex.commoditiesUnitValue} name="unitValue"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="documentOnly">Document Only</label>
            <input value={defaultDataFedex.documentOnly} name="documentOnly"></input>
          </div>
        </div>
        <h2 className="order-subtitle">Shipping Document Specification</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="shippingDoc">Shipping Document Type</label>
            <input value={defaultDataFedex.shippingDocumentType} name="shippingDoc"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="stockType">Stock Type</label>
            <input value={defaultDataFedex.stockType} name="stockType"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="documentFormatType">Document Format Type</label>
            <input value={defaultDataFedex.documentFormatType} name="documentFormatType"></input>
          </div>
        </div>
        <h2 className="order-subtitle">Shipping Information</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="serviceType">Service Type</label>
            <input value={defaultDataFedex.serviceType} name="serviceType"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="packagingType">Packaging Type</label>
            <input value={defaultDataFedex.packagingType} name="packagingType"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="pickupType">Pickup Type</label>
            <input value={defaultDataFedex.pickupType} name="pickupType"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="shippingChargesPayment">Shipping Charges Payment</label>
            <input value={defaultDataFedex.shippingChargesPayment} name="shippingChargesPayment"></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="blockInsightVisibility">Block Insight Visibility</label>
            <input value={defaultDataFedex.blockInsightVisibility} name="blockInsightVisibility"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="shipDateStamp">Ship Date Stamp</label>
            <input value={defaultDataFedex.shipDateStamp} name="shipDateStamp"></input>
          </div>
        </div>
        <h2 className="order-subtitle">Label Specification</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="imageType">Image Type</label>
            <input value={defaultDataFedex.imageType} name="imageType"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="labelStockType">Labe Stock Type</label>
            <input value={defaultDataFedex.labelStockType} name="labelStockType"></input>
          </div>
        </div>
        <h2 className="order-subtitle">Requested Package Line Items</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="requestedUnitWeight">Unit Weight</label>
            <input value={defaultDataFedex.requestedUnitWeight} name="requestedUnitWeight"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="requestedUnitValue">Unit Value</label>
            <input value={defaultDataFedex.requestedUnitValue} name="requestedUnitValue"></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="accountNumberValue">Account Number Value</label>
            <input value={defaultDataFedex.accountNumberValue} name="accountNumberValue"></input>
          </div>
        </div>
      </form>
    </>
  );
};
export default OrderInformation;
