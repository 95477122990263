import React, { useEffect } from "react"
import OrderProgressBar from "./order-progressbar"
import DataTable from "react-data-table-component"
import { useState } from "react"
import Loading from "../../../components/Loading"
import { customStylesTable } from "../../../services/modal"

const SendToFedex = ({ dataOrder, defaultDataFedex, page, pending }) => {

  const [total, setTotal] = useState(0)
  const [subtotal, setSubTotal] = useState(0)
  const [aux, setAux] = useState(0)
  useEffect(() => {
    window.scrollTo(0, 0)
    if(aux === 0){
      handleCalculateCost();
      setAux(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleCalculateCost = () => {
    let subtotalProducts = 0
    let subtotalSwatchs = 0
    dataOrder.detailProducts.map(item => {
      subtotalProducts = subtotalProducts + item.totalPrice
    })
    dataOrder.detailSwatch.map(item => {
      subtotalSwatchs = subtotalSwatchs + item.totalPrice
    })
    setSubTotal(subtotalProducts+subtotalSwatchs)
    setTotal(subtotalProducts+subtotalSwatchs)
  }

  const columns = [
    {
      name: "Item",
      center: true,
      cell: row => (
        <img
          src={`https://drive.google.com/thumbnail?authuser=0&sz=w120&id=${
            row.image.match(/[-\w]{25,}/)
              ? row.image.match(/[-\w]{25,}/)[0]
              : ""
          }`}
          alt="product"
          className="table_image"
        />
      ),
    },
    {
      name: "Article Number",
      center: true,
      selector: row => row.articleNumber,
    },
    {
      name: "Color Number",
      center: true,
      selector: row => row.colorNumber,
    },
    {
      name: "Quantity",
      center: true,
      selector: row => row.quantity,
    },
    {
      name: "Total Price",
      center: true,
      selector: row => "$ " + row.totalPrice,
    },
  ]

  return (
    <>
    {pending && (
      <>
        <Loading />
        <div className="message-loading">
          <div className="loading loading-card"></div>
          <p>Processing the shipment.... Please wait.</p>
        </div>
      </>
    )}
      <OrderProgressBar page={page}></OrderProgressBar>
      <div>
        <h2 className="order-subtitle">Send to fedex</h2>
        <div className="send-container">
          <h3 className="order-subtitle-h3">User Information</h3>
          <div className="send-container_data">
            <div>
              <p className="send-container_data_title">Company Name</p>
              <p className="send-container_data_text">
                {dataOrder.companyName}
              </p>
            </div>
            <div>
              <p className="send-container_data_title">User Name</p>
              <p className="send-container_data_text">{dataOrder.username}</p>
            </div>
            <div>
              <p className="send-container_data_title">Email</p>
              <p className="send-container_data_text">{dataOrder.email}</p>
            </div>
            <div>
              <p className="send-container_data_title">Phone</p>
              <p className="send-container_data_text">{dataOrder.phone}</p>
            </div>
          </div>
        </div>
        <div className="send-container">
          <h3 className="order-subtitle-h3">Shipping Information</h3>
          <div className="send-container_data">
            <div>
              <p className="send-container_data_title">Address</p>
              <p className="send-container_data_text">
                {dataOrder.shippingAddress.address}
              </p>
            </div>
            <div>
              <p className="send-container_data_title">Street</p>
              <p className="send-container_data_text">
                {dataOrder.shippingAddress.street}
              </p>
            </div>
            <div>
              <p className="send-container_data_title">City</p>
              <p className="send-container_data_text">
                {dataOrder.shippingAddress.city}
              </p>
            </div>
            <div>
              <p className="send-container_data_title">Zip Code</p>
              <p className="send-container_data_text">
                {dataOrder.shippingAddress.zipCode}
              </p>
            </div>
            <div>
              <p className="send-container_data_title">Phone Number</p>
              <p className="send-container_data_text">
                {dataOrder.shippingAddress.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <h2 className="order-subtitle">Product Details</h2>
        {dataOrder.detailProducts.length > 0 ? (
          <div className="table_style">
            <DataTable columns={columns} data={dataOrder.detailProducts} customStyles={customStylesTable}/>
          </div>
        ) : (
          <p className="no-data-order"> No Products on this order...</p>
        )}
        <h2 className="order-subtitle">Swatch Details</h2>
        {dataOrder.detailSwatch.length > 0 ? (
          <div className="table_style">
            <DataTable columns={columns} data={dataOrder.detailSwatch} customStyles={customStylesTable}/>
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
        <div className="order_price">
          <p className="order_price_subTotal">
            <span>Sub total: </span>
            <span>$ {subtotal.toFixed(2)}</span>
          </p>
          <p className="order_price_subTotal">
            <span>Shipping:</span>
            <span>$ How to get it</span>
          </p>
          <p className="order_price_subTotal">
            <span>Tax:</span>
            <span>$ How to calcule it</span>
          </p>
          <p className="order_price_total">
            <span>TOTAL:</span>
            <span>$ {total.toFixed(2)}</span>
          </p>
        </div>
      </div>
    </>
  )
}
export default SendToFedex
