import React, { useEffect } from "react"
import OrderProgressBar from "./order-progressbar"
import DataTable from "react-data-table-component"
import { customStylesTable } from "../../../services/modal"

const OrderValidation = ({ dataOrder, page }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const columns = [
    {
      name: "Item",
      center: true,
      cell: row => (
        <img
          src={`https://drive.google.com/thumbnail?authuser=0&sz=w120&id=${
            row.image.match(/[-\w]{25,}/)
              ? row.image.match(/[-\w]{25,}/)[0]
              : ""
          }`}
          alt="product"
          className="table_image"
        />
      ),
    },
    {
      name: "Article Number",
      center: true,
      selector: row => row.articleNumber,
    },
    {
      name: "Color Number",
      center: true,
      selector: row => row.colorNumber,
    },
    {
      name: "Quantity",
      center: true,
      selector: row => row.quantity,
    },
    {
      name: "Regular Price",
      center: true,
      selector: row => "$ " + row.regularPrice,
    },
    {
      name: "Actions",
      center: true,
      cell: row => <button className="order-validation-edit">Edit</button>,
    },
  ]
  return (
    <>
      <OrderProgressBar page={page}></OrderProgressBar>
      <form>
        <h2 className="order-subtitle">Customer Information</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="companyName">Company Name</label>
            <input value={dataOrder.companyName} name="companyName" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="userName">User Name</label>
            <input value={dataOrder.username} name="userName" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="email">Email</label>
            <input value={dataOrder.email} name="email" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="phone">Phone Number</label>
            <input value={dataOrder.phone} name="phone" disabled></input>
          </div>
        </div>
        <h2 className="order-subtitle">Shipping Address</h2>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="address">Address</label>
            <input value={dataOrder.shippingAddress.address} name="address" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="street">Street</label>
            <input value={dataOrder.shippingAddress.street} name="street" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="city">City</label>
            <input value={dataOrder.shippingAddress.city} name="city" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="state">State/Province</label>
            <input value="Where to find this?" name="state" disabled></input>
          </div>
        </div>
        <div className="form-style">
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="zip">Zip Code</label>
            <input value={dataOrder.shippingAddress.address} name="zip" disabled></input>
          </div>
          <div className="form-style__col col-md-3 col-sm-12">
            <label htmlFor="countryCode">Country Code</label>
            <input value="Where to find this?" name="countryCode" disabled></input>
          </div>
        </div>
        <h2 className="order-subtitle">Product Details</h2>
        {dataOrder.detailProducts.length > 0 ? (
          <div className="table_style">
            <DataTable
              columns={columns}
              data={dataOrder.detailProducts}
              customStyles={customStylesTable}
            />
          </div>
        ) : (
          <p className="no-data-order"> No Products on this order...</p>
        )}
        <h2 className="order-subtitle">Swatch Details</h2>
        {dataOrder.detailSwatch.length > 0 ? (
          <div className="table_style">
            <DataTable
              columns={columns}
              data={dataOrder.detailSwatch}
              customStyles={customStylesTable}
            />
          </div>
        ) : (
          <p className="no-data-order"> No Swatch on this order...</p>
        )}
      </form>
    </>
  )
}
export default OrderValidation
